/**
 * Determines all blips on the DOM and returns them as an Array.
 * @returns {Array} All blips from the DOM.
 */
const collectAllBlips = () => {
  // `getElementsByClassName` returns a HTMLCollection and needs converting to
  // an Array.
  return Array.from(document.getElementsByClassName('js--blip'));
};

/**
 * Determines all modals on the DOM and returns them as an Array.
 * @returns {Array} All modals from the DOM.
 */
const collectAllModals = () => {
  // `getElementsByClassName` returns a HTMLCollection and needs converting to
  // an Array.
  return Array.from(document.getElementsByClassName('js--modal'));
};

/**
 * Determines all videos on the DOM and returns them as an Array.
 * @param {String} elementClass A class name to search the DOM for.
 * @returns {Array} All videos from the DOM.
 */
const collectAllVideos = (elementClass = false) => {
  // `getElementsByTagName` returns a HTMLCollection and needs converting to
  // an Array.
  if (elementClass.length > 0 && elementClass.length !== false) {
    return Array.from(document.getElementsByClassName(elementClass));
  } else {
    return Array.from(document.getElementsByTagName('video'));
  }
};

/**
 * Determines all checkboxes with a particular class on the DOM and returns them as an Array.
 * @param {String} elementClass A class name to search the DOM for.
 * @returns {Array} All videos from the DOM.
 */
const collectAllCheckboxes = (elementClass = false) => {
  // `getElementsByClassName` returns a HTMLCollection and needs converting to
  // an Array.
  if (elementClass.length > 0 && elementClass.length !== false) {
    return Array.from(document.getElementsByClassName(elementClass));
  } else {
    return Array.from(document.getElementsByClassName('pledge__checkbox'));
  }
};

/**
 * Determines all checkboxes with a particular class and attribute on the DOM and
 * returns them as an Array.
 * @returns {Array} All videos from the DOM.
 */
const collectAllRequiredCheckboxes = () => {
  // `getElementsByClassName` returns a HTMLCollection and needs converting to
  // an Array.
  return Array.from(document.querySelectorAll('.pledge__checkbox[required]'));
};

exports.collectAllBlips = collectAllBlips;
exports.collectAllCheckboxes = collectAllCheckboxes;
exports.collectAllModals = collectAllModals;
exports.collectAllRequiredCheckboxes = collectAllRequiredCheckboxes;
exports.collectAllVideos = collectAllVideos;
