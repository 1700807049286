// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseBottle = () => {
  let timelineBottle = anime.timeline({
    autoplay: true,
    duration: 8500,
    direction: 'alternate',
    loop: true,
    easing: 'easeInOutSine',
  });

  timelineBottle.add({
    targets: '.illustration--bottle',
    left: ['75%', '45%'],
    bottom: ['45%', '55%'],
    scale: [1, 0.9],
    opacity: [1, 0.35],
  });
};

exports.initialiseBottle = initialiseBottle;
