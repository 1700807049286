// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseBlips = () => {
  // const glowKeyframes = [{boxShadow: '0 0 3px 0px #a0ffd8'}, {boxShadow: '0 0 6px 2px #a0ffd8'}, {boxShadow: '0 0 3px 0px #a0ffd8'}];
  const glowKeyframes = [{boxShadow: '0 0 28px 8px #a0ffd8'}, {boxShadow: '0 0 28px 16px #a0ffd8'}, {boxShadow: '0 0 28px 8px #a0ffd8'}];
  // const glowKeyframes = [{boxShadow: '0px 0px 28px 8px #a0ffd8;'}, {boxShadow: '0px 0px 50px 200px #a0ffd8;'}, {boxShadow: '0px 0px 28px 8px #a0ffd8;'}];

  const blipTimeline = anime.timeline({
    duration: 1000,
    easing: 'easeInOutSine',
    loop: true,
    autoStart: true,
  });

  blipTimeline.add({
    targets: '.blip__bg',
    keyframes: glowKeyframes,
    // opacity: [0.75, 0],
    // scale: [0.5, 1.75],
    // boxShadow: '0px 0px 28px 40px #ff0000',
  });
};

exports.initialiseBlips = initialiseBlips;
