/**
 * Returns a modified function with debounce-capabilities.
 * @param {Function} fn A function to debounce.
 * @param {Number} wait The amount of time to wait on debounce.
 * @returns Debounced function
 */
const debounce = (fn, wait = 1000) => {
  let timeout;
  // Return a new function with the provided arguments.
  return (...args) => {
    // Clean the previous function's timeout (if it exists).
    clearTimeout(timeout);
    // Create a new timeout, forcing the setTimeout function to be the provided
    // function with a timer.
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, wait);
  };
};

/**
 * Attaches an Event Listener to the document to load a function when ready.
 * @param {Function} fn A function to run when the document is ready.
 * @returns {Function} The initial function passed in.
 */
const ready = (fn) => {
  // Ensure the provided argument is actually a function.
  if (typeof fn !== 'function') return;

  // Determines if the document is ready to begin running functions.
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    return fn();
  }

  // Attach the provided function to the DOM's Content Loaded event to run ASAP.
  document.addEventListener('DOMContentLoaded', fn, false);
};

exports.debounce = debounce;
exports.ready = ready;
