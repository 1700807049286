import {viewportIsLargerThanMobile} from '../../bin/viewport';
import {debounce} from '../../bin/tools';

/**
 * Resets the mobile navigation state to 'closed' when the viewport has been
 * increased beyond a mobile-width.
 * @param {Boolean} force If true, ignores the mobile-width check.
 */
const resetMobileNavigationState = (force = false) => {
  // Cache elements.
  let mobileMenuClose = document.getElementById('js:dtft-navigation__control--close');
  let mobileMenuOpen = document.getElementById('js:dtft-navigation__control--open');

  // Determine if the viewport is beyond a mobile width.
  if (viewportIsLargerThanMobile() || force) {
    // Hide the navigation.
    mobileMenuClose.classList.add('icon--hidden');
    mobileMenuOpen.classList.remove('icon--hidden');
    document.getElementById('js:navigation').classList.remove('navigation--open');
    if (document.getElementById('fp-nav')) {
      document.getElementById('fp-nav').classList.remove('fp-nav--hidden');
    }
  }
};

/**
 * Attaches a window resize event listener to check for mobile navigation state
 * changes.
 */
const attachResetMobileNavigationStateEvents = () => {
  // Debounce the event to maintain good performance across resizing.
  window.addEventListener('resize', debounce(resetMobileNavigationState));
};

/**
 * Attaches multiple event listeners to the mobile navigation button to allow open
 * and close states of the navigation.
 */
const attachMobileNavigationButtonEvents = () => {
  // Cache elements.
  let mobileMenuButton = document.getElementById('js:navigation__control');
  let mobileMenuList = document.getElementById('js:navigation');
  let mobileMenuClose = document.getElementById('js:dtft-navigation__control--close');
  let mobileMenuOpen = document.getElementById('js:dtft-navigation__control--open');

  /**
   * Toggles a selection of classes.
   * The 'icon--menu' and 'icon--cross' classes toggle separately from one-another.
   * One will exist when the other doesn't.
   */
  const toggleClasses = (e) => {
    e.preventDefault();
    mobileMenuList.classList.toggle('navigation--open');
    mobileMenuClose.classList.toggle('icon--hidden');
    mobileMenuOpen.classList.toggle('icon--hidden');
    document.getElementById('fp-nav').classList.toggle('hidden');
  };
  // Add event listeners - click for desktop, touchend for mobile.
  mobileMenuButton.addEventListener('click', toggleClasses, false);
  mobileMenuButton.addEventListener('touchend', toggleClasses, false);
};

exports.resetMobileNavigationState = resetMobileNavigationState;
exports.attachResetMobileNavigationStateEvents = attachResetMobileNavigationStateEvents;
exports.attachMobileNavigationButtonEvents = attachMobileNavigationButtonEvents;
