const initialiseSectrionNavigationIcon = () => {
  document.getElementsByClassName('section-navigation__button')[0].addEventListener(
    'click',
    () => {
      fullpage_api.moveSectionDown();
    },
    true,
  );
};

exports.initialiseSectrionNavigationIcon = initialiseSectrionNavigationIcon;
