import {debounce} from './tools';

/**
 * Returns the pixel-width of the viewport (or variant, when unavailable).
 * @returns The pixel-width of the viewport.
 */
const determineWidthOfViewport = () => {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};

/**
 * Determines if the viewport is larger than the 'm-lg' breakpoint - 1024px.
 * @returns {Boolean}
 */
const viewportIsLargerThanMobile = () => {
  // Return 'true' when 16(px) * 64(rem) is equal to or greater than 1024px.
  return determineWidthOfViewport() >= 16 * 64 ? true : false;
};

/**
 * Creates an event on the document to rebuild fullsize after a set period.
 * When a viewport changes, the application needs to rebuild fullpage.js to allow
 * the mobile responsiveness to work correclty with the section features.
 */
const createFullpageRebuildDaemon = () => {
  window.addEventListener(
    'resize',
    debounce(() => {
      fullpage_api.reBuild();
    }),
  );
};

exports.createFullpageRebuildDaemon = createFullpageRebuildDaemon;
exports.determineWidthOfViewport = determineWidthOfViewport;
exports.viewportIsLargerThanMobile = viewportIsLargerThanMobile;
