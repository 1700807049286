// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseIbis1 = () => {
  let initialiseIbis1 = anime.timeline({
    autoplay: true,
    duration: 8000,
    loop: true,
    easing: 'easeInSine',
  });

  initialiseIbis1.add({
    targets: '.animation-layer--ibis',
    translateX: ['-50%', '20%'],
    translateY: ['120%', '-20%'],
    scale: [1, 0.9],
  });
};

exports.initialiseIbis1 = initialiseIbis1;
