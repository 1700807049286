// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseCloudsBridge = () => {
  let timelineClouds = anime.timeline({
    autoplay: true,
    duration: 80000,
    loop: true,
    easing: 'linear',
  });

  timelineClouds.add({
    // targets: '.clouds-bridge',
    targets: '.animation-layer--bridge-clouds',
    translateX: ['-50%', '0%'],
  });
};

exports.initialiseCloudsBridge = initialiseCloudsBridge;
