// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseArmsShake = () => {
  console.log('ARMS');
  let timelineClouds = anime.timeline({
    autoplay: true,
    duration: 600,
    direction: 'alternate',
    loop: true,
    easing: 'easeInOutSine',
    // delay: 1000,
  });

  timelineClouds.add({
    targets: '.animation-layer--man-arms',
    rotate: 1,
    translateY: ['0%', '0.3%'],
  });
};

exports.initialiseArmsShake = initialiseArmsShake;
