import {collectAllBlips} from '../../bin/elements';

/**
 * Attach an event to all blips that allow opening related modals based on a
 * unique id.
 */
const attachOpenEventsToBlips = () => {
  let blips = collectAllBlips();
  if (blips.length > 0) {
    blips.map((blip) => {
      // Determine what modal the blip is related to.
      let blipToModalId = blip.getAttribute('data-blip-id');
      // The application needs to make sure there's a related modal before applying
      // an event listener to prevent an error.
      if (blipToModalId) {
        blip.addEventListener('click', (e) => {
          // Traverse through the entire DOM to find one element with the associated
          // blip-id. The first modal is prioritised and others are ignored.
          // Once found, remove the hiding class.
          document.querySelector(`[data-modal-id="${blipToModalId}"]`).classList.remove('modal--closed');
          document.getElementById('fp-nav').classList.add('semi-hidden');
          document.getElementById('header').classList.add('semi-hidden');
          document.getElementById('js:footer-static').classList.add('semi-hidden');
        });
        blip.addEventListener('keydown', (e) => {
          // Traverse through the entire DOM to find one element with the associated
          // blip-id. The first modal is prioritised and others are ignored.
          // Once found, remove the hiding class.
          if (e.key == 'Enter') {
            document.querySelector(`[data-modal-id="${blipToModalId}"]`).classList.remove('modal--closed');
            document.getElementById('fp-nav').classList.add('semi-hidden');
            document.getElementById('header').classList.add('semi-hidden');
            document.getElementById('js:footer-static').classList.add('semi-hidden');
          }
        });
      }
    });
  }
};

exports.attachOpenEventsToBlips = attachOpenEventsToBlips;
