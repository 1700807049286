console.log('2023');
/**
 * Containers for Change - Don't Feed the Fill
 * David Woodward
 **/

// SCSS Imports
import '../scss/main_2023.scss';

// Bin Imports
import {ready} from './bin/tools';

// Viewport Imports
import {createFullpageRebuildDaemon} from './bin/viewport';

// Component Imports
import {attachCloseEventsToModals} from './components/modals/modal';
import {attachOpenEventsToBlips} from './components/modals/blips';
// import {initialiseApplicationNavigation} from './components/navigation/application2023';
import {initialiseDecision} from './components/decision';
// import {initialiseForm} from './components/form';
import {resetDesktopCTAState, attachResetDesktopCTAStateEvents} from './components/navigation/desktop';
import {resetMobileNavigationState, attachResetMobileNavigationStateEvents, attachMobileNavigationButtonEvents} from './components/navigation/mobile';

import {initialiseSectrionNavigationIcon} from './components/navigation/application2023';

// Animation Imports
import {initialiseIbis1} from './animation/iconography/ibis_01';
import {initialiseBlips} from './animation/blips';
import {initialiseBottle} from './animation/iconography/bottle';
import {initialiseGirlAndDog} from './animation/iconography/girl-and-dog';
// import {initialisePledge} from './animation/pledge';

import {initialiseClouds} from './animation/iconography/clouds';
import {initialiseGarbageTruck} from './animation/iconography/garbage-truck';
import {initialiseVan} from './animation/iconography/van';
import {initialiseCar} from './animation/iconography/car';

import {initialiseCloudsBridge} from './animation/iconography/clouds-bridge';
import {initialiseLandfillMouth} from './animation/iconography/landfill';
import {initialiseLandfillArm} from './animation/iconography/landfill';
import {initialiseArmsShake} from './animation/iconography/arms-shake';
import {initialiseBagShake} from './animation/iconography/bag-shake';

// node_modules Imports
// fullpage.js - https://alvarotrigo.com/fullPage/
import Fullpage from 'fullpage.js';

let hasBeenClicked = false;

let bodyId = document.body.id;

/**
 * Removes the loading overlay visibly from the viewport.
 * @param {Boolean} debug Determines if the overlay should be revealed.
 */
const removeOverlayFromViewport = (debug = false) => {
  // Exit early if in debug mode.
  if (debug) return;
  document.getElementById('js:overlay').classList.add('overlay--loaded');
};

let headerElement = document.getElementById('header');
let navigationTag = document.getElementById('js:navigation');
let footerStatic = document.getElementById('js:footer-static');

const setDefaultNavColour = (className) => {
  if (navigationTag.classList.contains(className)) {
    navigationTag.classList.remove(className);
    headerElement.classList.remove(className);
    footerStatic.classList.remove(className);
  }
};

/**
 * Initialises the fullpage.js system with the provided element.
 */
const initialiseFullpage = (id) => {
  if (bodyId == 'homePage') {
    new Fullpage(id, {
      allowScrolling: false,
      anchors: ['home', 'lair', 'reinvented', 'decision', 'landfill', 'redeemed', 'pledge'],
      controlArrows: false,
      licenseKey: '4AE7B90A-92FC4CFC-A0F02AC9-15AC9088',
      loopHorizontal: false,
      navigation: true,
      sectionsColor: ['#1e855a', '#424f45', '#1e855a', '#1e855a', '#283430', '#1e855a', '#1e855a'],
      touchSensitivity: false,
      verticalCentered: false,
      lockAnchors: false,
      animateAnchor: false,
      // scrollOverflow: true,
      // responsiveHeight: 700,
      onLeave: (origin, destination, direction) => {
        var destinationSlide = destination;

        switch (destinationSlide.anchor) {
          case 'home':
            // setDefaultNavColour('nav-white');
            headerElement.classList.remove('nav-white');
            navigationTag.classList.remove('nav-white');
            footerStatic.classList.remove('nav-white');
            break;
          case 'lair':
            headerElement.classList.add('nav-white');
            navigationTag.classList.add('nav-white');
            footerStatic.classList.remove('nav-white');
            break;
          case 'reinvented':
            headerElement.classList.remove('nav-white');
            navigationTag.classList.remove('nav-white');
            footerStatic.classList.remove('nav-white');
            // setDefaultNavColour('nav-white');
            break;
          case 'decision':
            headerElement.classList.add('nav-white');
            navigationTag.classList.add('nav-white');
            footerStatic.classList.remove('nav-white');
            break;
          case 'landfill':
            headerElement.classList.add('nav-white');
            navigationTag.classList.add('nav-white');
            footerStatic.classList.remove('nav-white');
            break;
          case 'redeemed':
            headerElement.classList.remove('nav-white');
            navigationTag.classList.remove('nav-white');
            footerStatic.classList.remove('nav-white');
            break;
          case 'pledge':
            headerElement.classList.add('nav-white');
            navigationTag.classList.add('nav-white');
            footerStatic.classList.add('nav-white');
            break;
          case 'signup':
            headerElement.classList.add('nav-white');
            navigationTag.classList.add('nav-white');
            footerStatic.classList.add('nav-white');
            break;
          default:
          // code block
        }
      },
    });
  } else if (bodyId == 'emptiesPage') {
    // on the empties page set full page
    new Fullpage(id, {
      allowScrolling: false,
      anchors: ['empties', 'chosen', 'coles'],
      controlArrows: false,
      licenseKey: '4AE7B90A-92FC4CFC-A0F02AC9-15AC9088',
      loopHorizontal: false,
      navigation: true,
      sectionsColor: ['#1e855a', '#1e855a', '#1e855a'],
      touchSensitivity: false,
      verticalCentered: false,
      lockAnchors: false,
      animateAnchor: false,
      // responsiveHeight: 700,

      onLeave: function (origin, destination, direction, trigger) {
        var destinationSlide = destination;

        switch (destinationSlide.anchor) {
          case 'empties':
            setDefaultNavColour('nav-white');
            break;
          case 'chosen':
            setDefaultNavColour('nav-white');
            break;
          case 'coles':
            navigationTag.classList.add('nav-white');
            headerElement.classList.add('nav-white');
            footerStatic.classList.add('nav-white');
            break;
          default:
          // code block
        }
      },
    });
  }
};

/**
 * Initialise the application.
 */
const initialise = () => {
  // Preemptively determine if the viewport exceeds the mobile width.
  resetMobileNavigationState();
  resetDesktopCTAState();

  // Attach the required event listeners to automate the navigation reset
  // process from herein.
  attachResetMobileNavigationStateEvents();
  attachResetDesktopCTAStateEvents();

  // Attach navigation control event listeners for control elements.
  attachMobileNavigationButtonEvents();

  // Initialise fullpage.js.
  initialiseFullpage('#fullpage');

  // Craete a resize daemon for fullpage.js.
  createFullpageRebuildDaemon();

  // Section Navigation icon.
  initialiseSectrionNavigationIcon();

  // Initialise blips and modal control.
  attachCloseEventsToModals();
  attachOpenEventsToBlips();

  // Initialise blip animations.
  initialiseBlips();

  // Determine if we're on the homepage.
  if (document.body.id == 'homePage') {
    // Initialise pledge form.
    // initialisePledge();
    // Initialise decision control.
    initialiseDecision();
    // Initialise form control.
    // initialiseForm();
    // Initialise Girl
    initialiseGirlAndDog();

    // Initialise Bottle
    initialiseBottle();

    initialiseBagShake();
    initialiseCloudsBridge();
    initialiseLandfillMouth();
    initialiseLandfillArm();

    initialiseIbis1();

    initialiseArmsShake();
  }

  // Remove the loading overlay once elements have been given an opportunity to
  // initialise and correct themselves.
  removeOverlayFromViewport();

  initialiseGarbageTruck();
  initialiseVan();
  initialiseCar();
  if (bodyId == 'emptiesPage') {
    initialiseClouds();
  }
};

// Begin the application.
ready(initialise);
