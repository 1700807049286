import {collectAllModals} from '../../bin/elements';

/**
 * Attaches closing events to all modals on the page.
 */
const attachCloseEventsToModals = () => {
  // Determine and cache all modals.
  let modals = collectAllModals();
  if (modals.length > 0) {
    // Iterate through all modals found.
    modals.map((modal) => {
      // Determine where the root element is - this is typically considered the
      // 'background' and prevents confusing the modal with the main content.
      let modalRootElement = modal.closest('.modal');
      // Determine where the close button is.
      let modalCloseButton = modal.querySelector('.modal__close');
      // Attach an event listener to close all modals on click.
      // This prevents modals from stacking when close one and helps prevent
      // soft-locking when misusing the system.
      modalRootElement.addEventListener('click', (e) => {
        // Close the modal(s) when the background is clicked or the close button
        // is clicked.
        if (e.target == modalRootElement || e.target == modalCloseButton) {
          // Reveal all navigation.
          document.getElementById('fp-nav').classList.remove('semi-hidden');
          document.getElementById('header').classList.remove('semi-hidden');
          if (document.getElementById('js:footer-static')) {
            document.getElementById('js:footer-static').classList.remove('semi-hidden');
          }
          // Close the modals.
          closeAllModals();
          if (document.body.id == 'homePage') {
            // Stop the YouTube video(s) if any are playing.
            player.stopVideo();
          }
        }
      });
      // Close the modal(s) when a user presses the 'Escape' key.
      document.addEventListener('keydown', (e) => {
        // Determine if the key pressed is 'Escape'.
        if (e.key == 'Escape') {
          // Reveal all navigation.
          document.getElementById('fp-nav').classList.remove('semi-hidden');
          document.getElementById('header').classList.remove('semi-hidden');
          if (document.getElementById('js:footer-static')) {
            document.getElementById('js:footer-static').classList.remove('semi-hidden');
          }
          // Close the modals.
          closeAllModals();
          if (document.body.id == 'homePage') {
            // Stop the YouTube video(s) if any are playing.
            player.stopVideo();
          }
        }
      });
    });
  }
};

/**
 * Iterates through all modals and 'closes' them by applying a styled class.
 */
const closeAllModals = () => {
  // Determine and cache all modals.
  let modals = collectAllModals();
  if (modals.length > 0) {
    // Iterate through all modals and apply the styled class to hide all modals
    // without it.
    modals.map((modal) => {
      modal.classList.add('modal--closed');
    });
  }
};

exports.attachCloseEventsToModals = attachCloseEventsToModals;
