// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseGarbageTruck = () => {
  let timelineClouds = anime.timeline({
    autoplay: true,
    duration: 15000,
    loop: true,
    easing: 'linear',
  });

  timelineClouds.add({
    targets: '.animation-layer--garbage-truck',
    translateX: ['15%', '-55%'],
  });
};

exports.initialiseGarbageTruck = initialiseGarbageTruck;
