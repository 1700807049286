// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseCar = () => {
  let timelineClouds = anime.timeline({
    autoplay: true,
    duration: 15000,
    loop: true,
    easing: 'linear',
  });

  timelineClouds.add(
    {
      targets: '.animation-layer--car',
      translateX: ['-55%', '15%'],
    },
    4000,
  );
};

exports.initialiseCar = initialiseCar;
