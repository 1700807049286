import {viewportIsLargerThanMobile} from '../../bin/viewport';
import {debounce} from '../../bin/tools';

/**
 * Resets the desktop CTA state to interactive when the viewport has been increased
 * beyond a mobile-width.
 * @param {Boolean} force If true, ignores the mobile-width check.
 */

const resetDesktopCTAState = (force = false) => {
  // Cache elements.
  let desktopCTA = document.getElementById('js:navigation--desktop-cta');
  if (desktopCTA) {
    // Determine if the viewport is beyond a mobile width.
    if (viewportIsLargerThanMobile() || force) {
      // Allow the user to tab to the desktop CTA when in a desktop viewport.
      desktopCTA.removeAttribute('tabIndex');
    } else {
      // Prevent the user from tabbing to the desktop CTA when in a mobile viewport.
      desktopCTA.setAttribute('tabIndex', -1);
    }
  }
};

/**
 
 * Attaches a window resize event listener to check for desktop CTA state changes.
 */
const attachResetDesktopCTAStateEvents = () => {
  // Debounce the event to maintain good performance across resizing.
  window.addEventListener('resize', debounce(resetDesktopCTAState));
};

exports.resetDesktopCTAState = resetDesktopCTAState;
exports.attachResetDesktopCTAStateEvents = attachResetDesktopCTAStateEvents;
