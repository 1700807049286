// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseVan = () => {
  let timelineClouds = anime.timeline({
    autoplay: true,
    duration: 15000,
    loop: true,
    easing: 'linear',
  });

  timelineClouds.add({
    targets: '.animation-layer--van',
    translateX: ['-55%', '15%'],
  });
};

exports.initialiseVan = initialiseVan;
