// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseBagShake = () => {
  let timelineClouds = anime.timeline({
    autoplay: true,
    duration: 1000,
    direction: 'alternate',
    loop: true,
    easing: 'easeInOutSine',
    // delay: 1000,
  });

  timelineClouds.add({
    targets: '.animation-layer--man-collecting-bag',
    rotate: 3,
  });
};

exports.initialiseBagShake = initialiseBagShake;
