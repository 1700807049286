// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseClouds = () => {
  let timelineClouds = anime.timeline({
    autoplay: true,
    duration: 24000,
    loop: true,
    easing: 'linear',
  });

  timelineClouds.add({
    targets: '.clouds',
    translateX: ['-50%', '0%'],
  });
};

exports.initialiseClouds = initialiseClouds;
