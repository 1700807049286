// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

let timelines = [];

const initialiseGirlAndDog = () => {
  let timelineGirlAndDog = anime.timeline({
    duration: 250,
    easing: 'easeInOutSine',
    autoplay: false,
    loop: false,
  });

  timelineGirlAndDog.add({
    targets: '.illustration--girl-and-dog',
    opacity: [0, 1],
  });

  timelines.push(timelineGirlAndDog);
};

const playGirlAndDogTimelines = () => {
  timelines.forEach((timeline) => {
    timeline.play();
  });
};

exports.initialiseGirlAndDog = initialiseGirlAndDog;
exports.playGirlAndDogTimelines = playGirlAndDogTimelines;
