// anime.js - https://animejs.com/
import anime from 'animejs/lib/anime.es.js';

const initialiseLandfillMouth = () => {
  let timelineClouds = anime.timeline({
    autoplay: true,
    duration: 2000,
    direction: 'alternate',
    loop: true,
    easing: 'easeInOutSine',
  });

  timelineClouds.add({
    targets: '.animation-layer--landfill-mouth',
    translateY: ['0%', '-2%'],
  });
};
const initialiseLandfillArm = () => {
  let timelineClouds = anime.timeline({
    autoplay: true,
    duration: 3000,
    direction: 'alternate',
    loop: true,
    easing: 'easeInOutSine',
    // delay: 1000,
  });

  timelineClouds.add({
    targets: '.animation-layer--landfill-arm',
    rotate: 10,
  });
};

exports.initialiseLandfillMouth = initialiseLandfillMouth;
exports.initialiseLandfillArm = initialiseLandfillArm;
