/**
 * Determine where the decision control elements are, and apply event listeners
 * to each for control of fullpage.js.
 */
const initialiseDecision = () => {
  document.getElementsByClassName('js--decision-choice-1')[0].addEventListener(
    'click',
    () => {
      // When the event is triggered, run the supplied function.
      fullpage_api.moveTo('landfill');
    },
    false,
  );
  document.getElementsByClassName('js--decision-choice-2')[0].addEventListener(
    'click',
    () => {
      // When the event is triggered, run the supplied function.
      fullpage_api.moveTo('redeemed');
    },
    false,
  );
  document.getElementById('js:quick-go-back').addEventListener(
    'click',
    () => {
      // When the event is triggered, run the supplied function.
      fullpage_api.moveTo('decision');
    },
    false,
  );
  document.getElementById('js:ready-to-be').addEventListener(
    'click',
    () => {
      // When the event is triggered, run the supplied function.
      fullpage_api.moveTo('pledge');
    },
    false,
  );
};

exports.initialiseDecision = initialiseDecision;
